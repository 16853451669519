<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <h3>Listing Referrals</h3>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <select class="form-control" v-model="status" @change="fetch">
            <option value="pending">Pending</option>
            <option value="hidden">Hidden</option>
            <option value="declined">Declined</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card" v-if="! loading">
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Description</th>
              <th>Region</th>
              <th>Phone Number</th>
              <th>User</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="listings.length == 0">
              <td colspan="6">There is no data to display.</td>
            </tr>
            <tr v-for="(listing, i) in listings" :key="`listing-${i}`">
              <td>{{ i+1 }}</td>
              <td>{{ listing.description }}</td>
              <td>{{ listing.region }}</td>
              <td>{{ listing.phone_number }}</td>
              <td>{{ listing.user }}</td>
              <td>
                <router-link :to="{ name: 'admin.listing-referrals.show', params: { id: listing.id }}">Details</router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination v-model="pagination.page" :records="pagination.records" @paginate="paginate" :perPage="10" />
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      listings: [],
      status: 'pending',
      pagination: { page: 1, records: 1 }
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/admin/listing-referrals?status=${this.status}`).then(response => {
        this.listings = response.data.listings
        this.pagination = response.data.pagination
        this.loading = false
      })
    }
  }
}
</script>